import { GET, POST, DELETE, PUT } from '@/config/fetch'

// 创建年度计划
export const createYearPlan = (params: any) =>
    POST('/front/dailylog/v1/yearPlan', params)

// 查询年计划
export const getYearPlanList = () =>
    GET('/front/dailylog/v1/yearPlan/list')
// 年度计划详情
export const getYearPlanDetail = (params: any) =>
    GET(`/front/dailylog/v1/yearPlan/${params}`)
// 年计划详情，根据id查
export const getYearPlanDetailById = (params: any) =>
GET(`/front/dailylog/v1/yearPlan/detail/${params}`)

// 年度计划修改
export const editYearPlanDetail = (id:string,params: any) =>
    PUT(`/front/dailylog/v1/yearPlan/${id}`,params)
// 年度计划导出
export const exportYearPlan = (params: any) =>
    GET(`/front/dailylog/v1/yearPlan/exportYearPlanExcel?id=${params}`,{fileName: '年度计划'},{responseType: 'blob'})