

































import { Component, Vue, Emit } from 'vue-property-decorator'
import {getYearPlanList} from '@/api/year'
@Component({
  components: {},
})
export default class Year extends Vue {
  isCreate:boolean = true
  yearList:any = [
    {
      year:new Date().getFullYear()
    },
    {
      year:new Date().getFullYear()+1
    }
  ]
  mounted() {
    console.log(`${process.env.VUE_APP_API_BASE_URL}${process.env.BASE_URL}`)
    this.getCurrentYearList()
  }
  createYear(year:string) {
    this.$router.push({path: '/year/addYearPlan',query:{createYear:year}})
  }
  goShowYearPlan(year:string) {
    this.$router.push({path: '/year/showYearPlan',query: {year:year}})
  }
  async getCurrentYearList() {
    const [err,res] =  await getYearPlanList()
    res.forEach((item:any,index:number)=> {
      if(index<=1) {
        if(item && item.year == new Date().getFullYear()) {
          this.$set(this.yearList,0,item)
        } else if(item && item.year == new Date().getFullYear()+1) {
          this.$set(this.yearList,1,item)
        }
      }
    })
  }
}
